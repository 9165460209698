<!--
 * @Author: lbh
 * @Date: 2022-12-15 16:29:17
 * @LastEditors: lbh
 * @LastEditTime: 2023-06-07 15:59:10
 * @Description: file content
-->
<template>
  <div class="member_system_graphic_text_edit">
    <div class="cell-b">
      <div class="cell-b">
        <h3>主標題</h3>
        <el-input
          v-for="lItem in language"
          :key="lItem.code"
          v-model="configs[`${lItem.code=='HK'?'':lItem.code}title`]"
          :placeholder="`請輸入-${lItem.name}`"
          @input="setValue(`${lItem.code=='HK'?'':lItem.code}title`)"
        />
      </div>

      <div class="cell-b">
        <h3>副標題 </h3>
        <el-input
          v-for="lItem in language"
          :key="lItem.code"
          v-model="configs[`${lItem.code=='HK'?'':lItem.code}subTitle`]"
          type="textarea"
          :placeholder="`請輸入-${lItem.name}`"
          @input="setValue(`${lItem.code=='HK'?'':lItem.code}subTitle`)"
        />
      </div>

    </div>
    <div class="cell-b">
      <h3>中心內容</h3>
      <selfCell title="圖標">
        <selfUpload
          v-model="configs.centerIcon"
          type="default"
          @change="setValue('centerIcon')"
        />
      </selfCell>
      <selfCell title="背景顏色">
        <el-color-picker
          v-model="configs.centerColor"
          show-alpha
          @change="colorChange"
        ></el-color-picker>
      </selfCell>
      <selfCell
        title="標題"
        :tipContent="$tipText.textWrap"
      >
        <div>
          <el-input
            v-for="lItem in language"
            :key="lItem.code"
            v-model="configs[`${lItem.code=='HK'?'':lItem.code}centerTitle`]"
            :placeholder="`請輸入-${lItem.name}`"
            @input="setValue(`${lItem.code=='HK'?'':lItem.code}centerTitle`)"
          />
        </div>
      </selfCell>
    </div>
    <div class="cell-b">
      <div
        v-for="(item,index) in configs.items"
        :key="index"
      >
        <div class="child-cell cell-b">
          <div class="cell">
            <selfUpload
              v-model="item.icon"
              type="default"
              @change="setValue('items')"
            />
            <!-- 顏色 -->
            <el-color-picker
              v-model="item.bgColor[0]"
              show-alpha
              @input="setValue('items')"
            ></el-color-picker>
            <el-color-picker
              v-model="item.bgColor[1]"
              show-alpha
              @input="setValue('items')"
            ></el-color-picker>
            <!-- 操作 -->
            <el-button-group class="btn-group">
              <el-button
                type="primary"
                icon="el-icon-upload2"
                :disabled="index == 0"
                size="mini"
                @click="itemCheck(index,index-1)"
              ></el-button>
              <el-button
                type="success"
                icon="el-icon-download"
                :disabled="index == configs.items.length-1"
                size="mini"
                @click="itemCheck(index,index+1)"
              ></el-button>
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                :disabled="configs.items.length == 1"
                @click="itemDel(index)"
              ></el-button>
              <el-button
                type="warning"
                icon="el-icon-plus"
                size="mini"
                @click="itemAdd(index)"
              ></el-button>
            </el-button-group>
          </div>
          <!-- 文本 -->
          <el-input
            v-for="lItem in language"
            :key="lItem.code"
            v-model="item[`${lItem.code=='HK'?'':lItem.code}text`]"
            @input="setValue('items')"
            :placeholder="`請輸入-${lItem.name}`"
            class="itemText"
          ></el-input>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import langEditMixin from '../../mixins/langEdit'
export default {
  mixins: [langEditMixin],
  props: {
    configs: {
      default () {
        return {
          bgColor: "",
          itemPadding: "0",
          items: [],
          subTitle: "",
          title: "",
          type: '',
        };
      },
    },
    index: {
      default () {
        return 0;
      },
    },
  },
  methods: {
    setValue (key) {
      this.$emit('setValue', { key: key, value: this.configs[key] });
    },
    colorChange (e) {
      this.$emit('setValue', { key: 'centerColor', value: e });
    },
    // 切換順序
    itemCheck (index, newIndex) {
      let items = this.configs.items;
      let _ = items.splice(index, 1);
      items.splice(newIndex, 0, _[0])
      this.$emit('setValue', { key: 'items', value: items });
    },
    // 刪除
    itemDel (index) {
      let items = this.configs.items;
      items.splice(index, 1);
      this.$emit('setValue', { key: 'items', value: items });
    },
    // 新增
    itemAdd (index) {
      let items = this.configs.items;
      items.splice(index + 1, 0, this.$util.object.clone(items[index]))
      this.$emit('setValue', { key: 'items', value: items });
    }
  }
}
</script>

<style lang="less" scoped>
.member_system_graphic_text_edit {
  .child-cell {
    margin-bottom: 10px;
    .cell {
      display: flex;
      align-items: center;

      & /deep/ .com__upload-box {
        max-width: 40px;
        max-height: 40px;
        margin-right: 6px;
      }
      .itemIcon,
      .itemText {
        margin-right: 10px;
      }
      .btn-group {
        margin-left: auto;
      }
      margin-bottom: 5px;
    }
  }
}
</style>